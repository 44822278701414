import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Naman Bajpai  </span>
            from <span className="purple"> Philadelphia,PA.</span>
            <br />
            I am currently hustling away at my startup ClassCut.
            <br />
            I am Persuing a BSc( Computer-Science, Entreprenuship) and a Minor in Mechaninchal Engineering.
            
            <br />
            <br />
            Apart from coding, some other activities that I love to do!
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Music Production
            </li>
            <li className="about-activity">
              <ImPointRight /> Red Teaming 
            </li>
            <li className="about-activity">
              <ImPointRight /> Squash 
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            "A person only falls down to the heights of their processes ."{" "}
          </p>
          <footer className="blockquote-footer">Naman</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
