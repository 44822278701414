import React, { useEffect } from "react";

function ResumeNew() {
  useEffect(() => {
    // Function to open the Google Drive link in a new tab
    const openLinkInNewTab = () => {
      const url = "https://drive.google.com/file/d/1R_UB6LwMwJkWczcGeusrpU_Shiygfer2/view?usp=sharing";
      window.open(url, "_blank");
    };

    // Call the function when the component mounts
    openLinkInNewTab();
  }, []); // Empty dependency array ensures this effect runs only once on mount

  return null; // Returning null since no actual rendering is needed
}

export default ResumeNew;
